import axios from 'axios'
import { Message, Loading } from 'element-ui'
let loadingInstance = null //这里是loading

// 等於運行環境 就使用代理路由  非運行環境 就使用域名
let baseURL = process.env.VUE_APP_BASEURL
// if (process.env.NODE_ENV === 'development') {
//   baseURL = process.env.VUE_APP_BASEURL
// } else {
//   baseURL = "https://api-jept.feibi.site"
//   // baseURL = window.location.protocol + "//" + window.location.host
// }

const Service = axios.create({
  baseURL: baseURL,
  timeout: 100000
})

// 添加请求拦截器
Service.interceptors.request.use(config => {
  let token = sessionStorage.getItem('token')
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`
  }
  // loadingInstance = Loading.service({
  //   lock: true,
  //   text: '载入中...',
  //   spinner: 'el-icon-loading',
  //   background: 'rgba(0, 0, 0, 0.7)'
  // })
  return config
})

// 添加响应拦截器
Service.interceptors.response.use(response => {
  // 关闭载入中动画
  // loadingInstance.close()
  const res = response.data
  if ([200, 4001, 4002].includes(res.status)) {
    return res
  } else { // 请求异常
    Message({
      message: res.msg || '請求異常，請聯繫管理員處理！',
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(new Error(res.msg || 'Error'))
  }
}, error => {
  // 关闭载入中动画
  // loadingInstance.close()
  Message({
    message: error.msg || '請求異常，請聯繫管理員處理！',
    type: 'error',
    duration: 5 * 1000
  })
  return Promise.reject(error)
})


/**
 * post方法，对应post請求
 * @param {String} url [請求的url地址]
 * @param {Object} params [請求时携带的参数]
 * @param {String} type [post请求类型 json form-date 默认json]
 * @param {Object} headers [请求头参数]
 */
 export function post(url, params, type = 'json', headers) {
  return new Promise((resolve, reject) => {
    const data = type === 'json' ? params : QS.stringify(params)
    Service.post(url, data, headers)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}