<template>
  <transition
    enter-active-class="animated fadeIn"
    leave-active-class="animated fadeOut"
  >
    <div class="app-popup" v-show="show">
      <transition
        enter-active-class="animated zoomIn"
        leave-active-class="animated zoomOut"
      >
        <div class="detection-popup" v-if="show">
          <img class="detection-popup-close" @click="close" src="@/assets/image/test/icon-closes.png" alt="">
          <h2 class="detection-popup-h2">{{ dataForm.name }}</h2>
          <div class="detection-popup-div">
            <img :src="dataForm.content" alt="">
          </div>
        </div>
    </transition>
    </div>
  </transition>
</template>

<script>
import { questionHardwareProblem } from "@/request/api"
export default {
  name: 'DetectionPopup',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'headphone'
    }
  },
  data() {
    return {
      dataForm: {}
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      questionHardwareProblem().then(res => {
        switch(this.type) {
          case 'headphone':
            this.dataForm = res.data.headphone
            break
          case 'microphone':
            this.dataForm = res.data.microphone
            break
          case 'lens':
            this.dataForm = res.data.lens
            break
          case 'network_speed':
            this.dataForm = res.data.network_speed
            break
          case 'screen_sharing':
            this.dataForm = res.data.screen_sharing
            break
        }
      })
    },
    close() {
      this.show = false
    }
  }
}
</script>

<style lang="less" scoped>
.detection-popup {
  padding: 60px;
  width: 720px;
  background: #FFFFFF;
  border-radius: 10px;
  position: relative;
  .detection-popup-close {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
  }
  .detection-popup-h2 {
    margin-bottom: 16px;
    font-family: "Microsoft JhengHei","微軟正黑體";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 43px;
    letter-spacing: 0.01em;
    color: #000000;
    text-align: center;
  }
  .detection-popup-div {
    width: 100%;
    height: 510px;
    overflow-y: auto;
    text-align: center;
    img {
      max-width: 100%;
      width: auto;
      height: auto;
    }
  }
  
  // /* 滚动条美化 */
  // ::-webkit-scrollbar-track {
  //   background: #F1F1F1;
  //   border-radius: 0;
  // }

  // ::-webkit-scrollbar {
  //   -webkit-appearance: none;
  //   width: 10px;
  //   height: 10px;
  // }

  // ::-webkit-scrollbar-thumb {
  //   cursor: pointer;
  //   border-radius: 5px;
  //   background: #0C4FA2;
  //   transition: color .2s ease;
  // }
}
</style>